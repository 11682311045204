@media only screen and (max-width: 960px) {
  body {
    background-image: url(./images/bg-mobile.svg), linear-gradient(#000E1E, #001C39);
  }
  .header {
    width: auto;
    margin: 0;
    padding: 30px;
    box-sizing: border-box;
  }
  #section-about {
    width: auto;
    margin: 0;
    padding: 50px 30px;
    box-sizing: border-box;
  }
  #section-about > .main-info > .description {
    padding-right: 50px;
  }
  #section-functionalities {
    width: auto;
    margin: 0;
    padding: 100px 30px;
    box-sizing: border-box;
    display: block;
    text-align: center;
  }
  #section-functionalities > .functionality {
    display: inline-block;
    margin: 15px;
    margin-top: 50px;
  }
  #banner {
    padding: 40px 30px;
    box-sizing: border-box;
  }
  #banner .banner-stores a {
    width: auto;
    height: auto;
    padding: 0;
    text-align: center;
    background: none;
    img {
      margin-top: 0;
    }
  }
  .banner-container {
    width: auto;
    margin: 0;
  }
  .banner-text {
    padding-right: 30px;
  }
  #section-ratings {
    padding: 100px 30px;
  }
  .author-photo {
    margin-right: 50px;
    width: 50%;
  }
  .rate-info {
    width: 50%;
  }
  .rate-info > .comment {
    width: auto;
  }
  .footer {
    padding: 30px;
  }
  .footer-container {
    width: auto;
    margin: 0;
  }
  .footer-copyright > .container {
    width: auto;
    margin: 0;
  }
}

@media only screen and (max-width: 800px) {
  #section-about {
    display: block;
    text-align: center;
  }
  #section-about > .main-info {
    margin-bottom: 30px;
    padding-top: 0;
  }
  #section-about > .main-info > .description {
    padding-right: 0;
  }
  #section-functionalities {
    padding: 50px 30px;
  }
  #section-functionalities > .functionality {
    margin-bottom: 30px;
  }
  .banner-container {
    display: block;
    text-align: center;
  }
  .banner-text {
    margin-bottom: 30px;
    padding-right: 0;
  }
  .banner-stores {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .banner-stores > a:not(:last-child) > img {
    margin-bottom: 0;
    margin-right: 15px;
  }
  #section-ratings {
    flex-direction: column;
    padding: 50px 30px;
  }
  .author-photo {
    height: 350px;
    width: 250px;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .rate-info {
    width: auto;
  }
  .rate-info > .title {
    display: none;
  }
  .rate-info > .comment,
  .rate-info > .author {
    text-align: center;
  }
  .footer {
    padding-bottom: 5px;
  }
  .footer-stores {
    text-align: center;
  }
  .footer-credits {
    display: block;
    text-align: center;
  }
  .senasp-and-mj {
    margin-bottom: 15px;
  }
  .footer-copyright > .container {
    display: block;
    text-align: center;
  }
  .footer-copyright > .container > .insight-lab {
    margin-bottom: 15px;
  }
  .footer-copyright > .container > .policies {
    flex-direction: column;
  }
  .privacy-policy {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 700px) {
  .header {
    justify-content: center;
  }
  .menu {
    display: none;
  }
  .banner-text,
  .rate-info > .comment {
    font-size: 36px;
  }
}

@media only screen and (max-width: 500px) {
  #section-about > .screens-imgs > img {
    height: auto;
    width: 90%;
  }
  .banner-text,
  .rate-info > .comment {
    font-size: 30px;
  }
  .rate-info > .author {
    font-size: 14px;
  }
}

@media only screen and (max-width: 420px) {
  #section-about > .main-info > .title {
    font-size: 32px;
  }
  .banner-text,
  .rate-info > .comment {
    font-size: 26px;
  }
  .rate-info > .author {
    font-size: 12px;
  }
  #section-about > .main-info > .stores,
  .banner-stores,
  .footer-stores {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #section-about > .main-info > .stores > a:first-child,
  .banner-stores > a:not(:last-child) > img,
  .footer-stores > a:not(:last-child) {
    margin-right: 0;
  }
  #section-about > .main-info > .stores > a:first-child > img,
  .banner-stores > a:not(:last-child) > img,
  .footer-stores > a:not(:last-child) > img {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 370px) {
  #section-about > .main-info > .title {
    font-size: 26px;
  }
  .banner-text,
  .rate-info > .comment {
    font-size: 20px;
  }
  #section-functionalities > .functionality {
    width: 90%;
  }
  .func-image > img {
    width: 90%;
    margin-top: -40px;
  }
  .author-photo {
    width: 90%;
    height: 250px;
  }
  .logo-mj > img,
  .logo-mj-gov > img {
    width: 100%;
  }
}