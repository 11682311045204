
@font-face {
  font-family: "Fira Sans Bold-italic";
  src: url(./fonts/FiraSans-BoldItalic.ttf)
}
@font-face {
  font-family: "Poppins Semi-bold";
  src: url(./fonts/Poppins-SemiBold.ttf)
}
@font-face {
  font-family: "Poppins Light";
  src: url(./fonts/Poppins-Light.ttf)
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  color: #fff;
  background-image: url(./images/bg.svg), linear-gradient(#000E1E, #001C39);
  background-size: cover;
  background-repeat: no-repeat;
}

a {
  text-decoration: none;
  color: #fff;
}
